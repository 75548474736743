import axios from "axios";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import { cloneDeep } from "lodash";

import {
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useStyles } from "app/study/CreateEditStudyFormStyles";
import { useAlerts, useUsers } from "common";
import isEmpty from "lodash/isEmpty";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useFilters } from "app/services/filterService";
import { useStudies } from "app/services/studiesService";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import {
  GET_STUDIES_URL_BASE,
  RecruitmentPreferences,
  StudyContactRole,
} from "app/shared/constants";
import {
  differenceObject,
  getPrefByName,
  isValidUser,
  mapStudyToDisplayed,
  removeTzFromLocalDatetime,
} from "app/shared/utils";
import CreateEditStudyMain from "app/study/CreateEditStudyMain";
import StudyContactsTab from "app/study/StudyContactsTab";
import StudyDetailsTab from "app/study/StudyDetailsTab";
import StudyPreferencesTab from "app/study/StudyPreferencesTab";

import StudyTargetAccrualsTab from "./StudyTargetAccrualsTab";

// component to display tab header
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box m={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CreateEditStudyForm = (props) => {
  const { state, pathname } = props.location;
  const classes = useStyles();
  const createMode = state
    ? state.createMode
    : pathname === "/studies/new"
    ? true
    : false;
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { allHonestBrokers, setTriggerFiltersRefetch } = useFilters();
  const { errors, handleSubmit, control, reset, formState, trigger, setError } =
    useForm({
      mode: "onChange",
    });
  let history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [disableCreate, setDisableCreate] = useState(createMode ? true : false);

  const [initData, setInitData] = useState(
    state && state.study ? cloneDeep(state.study) : {}
  );

  const [badTargetFields, setBadTargetFields] = useState({});

  const { studies, setStudies, benchmarks } = useStudies();

  const [isOptimizationEnabled, setIsOptimizationEnabled] = useState(
    initData.targetAccruals
      ? initData.targetAccruals.isOptimizationEnabled
      : benchmarks?.isOptimizationEnabled);

  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const [study, setStudy] = useState(null);
  const [isCollectedDataDirty, setCollectedDataDirty] = useState(false);
  const { setAlert } = useAlerts();
  const [crcError, setCrcError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const saveButtonDisableCheck = useMemo(() => {
    let badTargs = false;
    for (let key in badTargetFields) {
      if (key.match(/^categor/) && badTargetFields[key]) {
        badTargs = true;
        break;
      }
    }

    return badTargs || isSaving ||
      (!isCollectedDataDirty &&
        (!formState.isDirty ||
          (formState.isDirty &&
            formState.dirtyFields?.hasOwnProperty("irbNumber"))));

  }, [isCollectedDataDirty, badTargetFields, formState, isSaving]);

  useEffect(() => {
    if (!state && pathname.includes("/edit")) {
      // if authorized user attemp to refresh study edit page, fetch the study by id in url
      // pathname pattern is "/studies/:studyId/edit"
      const index1 = pathname.lastIndexOf("/");
      const studyId = pathname.substring(9, index1);

      // fetch study by id
      axios
        .get(GET_STUDIES_URL_BASE + "/" + studyId)
        .then((res) => {
          const mappedStudy = mapStudyToDisplayed(res.data);
          setInitData(mappedStudy);
          setIsOptimizationEnabled(
            mappedStudy.targetAccruals?.isOptimizationEnabled
          );
          setStudy(mappedStudy); // save this for comparison at edit save
        })
        .catch((err) => {
          setAlert("error", err.message);
          if (!err.response) {
            err.response = {};
          }
          err.response.errorProcessed = true;
        });
    }
  }, [state, pathname, setAlert]);

  useEffect(() => {
    if (createMode && initData && !initData.targetAccruals && benchmarks) {
      initData.targetAccruals = benchmarks;
      setIsOptimizationEnabled(benchmarks.isOptimizationEnabled);
      reset({
        recruitmentStartDate:
          initData.recruitmentStartDate &&
          new Date(initData.recruitmentStartDate),
        recruitmentEndDate:
          initData.recruitmentEndDate && new Date(initData.recruitmentEndDate),
      });
    }
    // eslint-disable-next-line
  }, [initData, benchmarks, createMode, setIsOptimizationEnabled]);

  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    if (cancelChoice) {
      history.push("/");
    }
  };

  const handleCancelCreate = () => {
    if (formState.isDirty || isCollectedDataDirty) {
      setOpenASConfirmation(true);
    } else {
      history.push("/");
    }
  };

  const validateTabAndMainAsync = async (tabValue, globalSave) => {
    // trigger validation first, and then save if all fields are valid
    const mainField = ["title"];
    const validationFields = [
      [
        "division",
        "honestBroker",
        "recruitmentStartDate",
        "recruitmentEndDate",
      ],
      ["recruitmentEmail", "recruitmentPhone"],
      [
        "prefChannels",
        "prefSource",
        "prefNotContact",
        "prefProspectActive",
        "prefOnboardingCapacity",
      ],
      [],
    ];
    // At index 3, it is for TargetAccruals tab. Nothing here yet

    return await trigger(
      globalSave
        ? validationFields[tabValue].concat(mainField)
        : validationFields[tabValue]
    );
  };
  /*
   * check if there is one and only one lead crc
   */
  const validateLeadCrc = () => {
    const leadCrcs = initData.studyContacts.filter(
      (contact) => contact.role === StudyContactRole.PRIMARY_CRC
    );
    const mainCrcs = initData.studyContacts.filter(
      (contact) =>
        contact.role === StudyContactRole.PRIMARY_CRC &&
        contact.leadCrc === true
    );
    if (leadCrcs && leadCrcs.length > 0 && mainCrcs.length !== 1) {
      setCrcError(true);
      window.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
      return false;
    }
    setCrcError(false);
    return true;
  };

  const validateOnboardingCapacity = (prefOnboardingCapacity) => {
    return (
      prefOnboardingCapacity ||
      getPrefByName(initData, RecruitmentPreferences[7]).value
    );
  };

  const validateSaveAndSwitch = (newTab, globalSave) => {
    // before we navigate away to a new tab, we want RHF to validate the
    // rendered inputs on this page because when components are not visible
    // they won't be available in RHF and the application needs to manage/
    // collect the input data from the hidden tabs ourselves
    const values = control.getValues();

    validateTabAndMainAsync(activeTab, globalSave)
      .then((result) => {
        // at this point, RFH validation on the visible components
        // are passed, we can continue on to business logic validation
        if (result) {
          if (activeTab === 0) {
            // special validation for detail tab RecruitmentStartDate and
            // recruitmentEndDate. End date need to be greater than start date
            if (
              values.recruitmentEndDate &&
              values.recruitmentStartDate &&
              values.recruitmentEndDate.getTime() <
                values.recruitmentStartDate.getTime()
            ) {
              setAlert("error", t("formValidation.recruitmentStartEndDate"));
              return;
            }
          }

          if (activeTab === 2) {
            const re = /^[0-9\b]+$/;

            if (
              !re.test(values.prefOnboardingCapacity) ||
              values.prefOnboardingCapacity < 1 ||
              values.prefOnboardingCapacity > 999999
            ) {
              setError("prefOnboardingCapacity", {
                type: "required",
              });
              return;
            }
          }

          let collectedMainData = collectMainPanelData(values);
          let collectedTabData = {};
          // validation true, then proceed to collect user input
          switch (activeTab) {
            case 0:
              collectedTabData = collectDetailsTabData(values);
              break;
            case 1:
              collectedTabData = collectContactsTabData(values);
              break;
            case 2:
              collectedTabData = collectPreferencesTabData(values);
              break;
            case 3:
              collectedTabData = collectTargetAccrualsTabData(values);
              break;
            default:
              break;
          }
          // cache the new changes
          let newInitData = {
            ...initData,
            ...collectedMainData,
            ...collectedTabData,
          };
          setInitData(newInitData);

          // special validation for contacts tab's main crc checkbox
          // to make sure there is one and only one marked as leadCrc
          // if failed, display an error message and return
          if (!validateLeadCrc()) {
            if (activeTab !== 1) {
              // before we switch tab, test and set the isCollectedDataDirty flag
              let changedFields = findChangedStudyFields(newInitData);
              setCollectedDataDirty(!isEmpty(changedFields));
              setActiveTab(1);
            }
            return;
          }

          if (
            !validateOnboardingCapacity(values.prefOnboardingCapacity) &&
            globalSave
          ) {
            setError("prefOnboardingCapacity", {
              type: "required",
            });
            if (activeTab !== 2) {
              setActiveTab(2);
            }
            return;
          }

          // validation and save tabs is done, check to see if need to switch tab
          if (activeTab !== newTab) {
            // before we switch tab, test and set the isCollectedDataDirty flag
            let changedFields = findChangedStudyFields(newInitData);
            setCollectedDataDirty(!isEmpty(changedFields));
            setActiveTab(newTab);
          }

          if (globalSave) {
            saveAndCreateUpdateStudy(newInitData);
          }
        }
      })
      .catch((err) => {
        setAlert("error", err.message);
      });
  };

  const collectMainPanelData = (values) => {
    const mainFields = {
      nickname: values.nickName,
      title: values.title,
      note: values.studyNote,
      followUpDate: values.followUpDate,
    };

    if (!createMode) {
      mainFields.recruitmentStatus = values.recruitmentStatus;
      mainFields.suspended = values.suspended;
    }
    return mainFields;
  };

  const collectDetailsTabData = (values) => {
    const detailsData = {
      division: values.division,
      therapeuticArea:
        values.therapeuticArea === 0 ? null : values.therapeuticArea,
      fundingSource: values.fundingSource === 0 ? null : values.fundingSource,
      honestBroker: { id: values.honestBroker },
      recruitmentStartDate: new Date(values.recruitmentStartDate),
      recruitmentEndDate: new Date(values.recruitmentEndDate),
    };
    return detailsData;
  };

  const collectContactsTabData = (values) => {
    // contact note

    const initContacts = [...initData.studyContacts];
    const newContacts = initContacts.map((contact) => {
      let rObj = { ...contact };
      const fieldName = `${contact.staffId}_${contact.role}_note`;
      if (values.hasOwnProperty(fieldName)) {
        // note text area has value
        rObj.note = values[fieldName];
      }
      return rObj;
    });

    const newTabData = {
      recruitmentEmail: values.recruitmentEmail,
      recruitmentPhone: values.recruitmentPhone,
      studyContacts: newContacts,
    };
    return newTabData;
  };

  const collectPreferencesTabData = (values) => {
    const initPrefs = [...initData.recruitmentPreferences];
    const newPrefs = initPrefs.map((pref) => {
      let rObj = { ...pref };
      switch (rObj.name) {
        case "OR_ENABLE_CHANNELS":
          rObj.value = values["prefChannels"].toString();
          break;
        case "CH_INCLUDE_ENTITIES":
          rObj.value = values["prefSource"].toString();
          break;
        case "CH_INCLUDE_ZIP_CODES":
          rObj.value = values["prefIncludeZipCode"].trim();
          break;
        case "OR_NO_CONTACT_PROSPECT_WITHIN_X_DAYS":
          rObj.value = values["prefNotContact"].toString();
          break;
        case "OR_PROSPECT_ACCOUNT_LAST_ACCESS_WITHIN_X_DAYS":
          rObj.value = values["prefProspectActive"].trim();
          break;
        case "CH_INCLUDE_UNDECIDED":
          rObj.value = values["prefIncludeUndecided"].toString();
          break;
        case "OR_STUDY_ONBOARDING_CAPACITY":
          rObj.value = values["prefOnboardingCapacity"].toString();
          break;
        default:
          break;
      }

      return rObj;
    });

    return { recruitmentPreferences: newPrefs };
  };

  const collectTargetAccrualsTabData = (values, globalSave) => {
    const categories =
      initData.targetAccruals?.categories.map((cSpec, cSpecInd) => ({
        ...cSpec,
        values: cSpec.values.map((v, vInd) => ({
          ...v,
          target: +values.categoriesOut[cSpecInd].values[vInd].target,
        })),
      })) || [];

    const initTargetAccruals = {
      ...initData.targetAccruals,
      isOptimizationEnabled: values.targetAccruals?.isOptimizationEnabled,
      categories: categories,
    };

    return { targetAccruals: initTargetAccruals };
  };

  const processArrays = (target, source) => {
    // check if studyContacts has changes
    if (target.studyContacts) {
      const newContacts = target.studyContacts
        .map((contact, index) => {
          if (contact != null) {
            contact.id = source.studyContacts[index].id;
            return contact;
          } else {
            return null;
          }
        })
        .filter((contact) => contact != null);

      target.studyContacts = newContacts;
    }
    // check if recruitmentPreferences has changes
    if (target.recruitmentPreferences) {
      const newPrefs = target.recruitmentPreferences
        .map((pref, index) => {
          if (pref != null) {
            pref.id = source.recruitmentPreferences[index].id;
            return pref;
          } else {
            return null;
          }
        })
        .filter((pref) => pref != null);

      target.recruitmentPreferences = newPrefs;
    }

    return target;
  };

  const findChangedStudyFields = (newInitData) => {
    const origStudy = state && state.study ? state.study : study;
    if (origStudy) {
      const diff1 = differenceObject(newInitData, origStudy);
      const newDiff = processArrays(diff1, newInitData);
      return newDiff;
    }
    // if origStudy is empty, we must be in CREATE mode
    return newInitData;
  };

  const transformTimezone = (values) => {
    if (values.followUpDate) {
      values.followUpDate = removeTzFromLocalDatetime(values.followUpDate);
    }
    if (values.recruitmentStartDate) {
      values.recruitmentStartDate = removeTzFromLocalDatetime(
        values.recruitmentStartDate
      );
    }
    if (values.recruitmentEndDate) {
      values.recruitmentEndDate = removeTzFromLocalDatetime(
        values.recruitmentEndDate
      );
    }

    if (values.targetAccruals && values.targetAccruals.lastUpdated) {
      values.targetAccruals.lastUpdated = removeTzFromLocalDatetime(
        values.targetAccruals.lastUpdated
      );
    }
    return values;
  };

  const saveAndCreateUpdateStudy = (newInitData) => {
    // initData includes the data changes collected in the tabs
    // we need to merge it
    if (createMode) {
      const newDataDropTz = {
        ...newInitData,
        ...transformTimezone(newInitData),
      };
      createStudy(newDataDropTz);
    } else {
      if (!formState.isDirty && !isCollectedDataDirty) {
        // if no change, navigate back to study dashboard
        history.push({ pathname: "/" });
        return;
      }
      const newDataDropTz = {
        id: initData.id,
        ...transformTimezone(findChangedStudyFields(newInitData)),
      };
      newDataDropTz.targetAccruals = newDataDropTz.targetAccruals || {
        isOptimizationEnabled: isOptimizationEnabled,
      };

      updateStudy(newDataDropTz);     
    }
  };

  const createStudy = (newData) => {
    axios
      .post(GET_STUDIES_URL_BASE, newData)
      .then((res) => {
        // save the newly created study to the top of studies
        let newStudies = [...studies];
        newStudies.unshift(res.data);
        setStudies(newStudies);
        // check for any partial failures
        if (
          res.data.partialFailureMessage &&
          res.data.partialFailureMessage !== null
        ) {
          setAlert("error", res.data.partialFailureMessage);
        }

        // after success save, navigate back to studydashboard
        history.push({ pathname: "/", highlightRow: -1 });

        // Modify FiltersContext state to force refetch of filters panel data from backend
        setTriggerFiltersRefetch((prevState) => prevState + 1);
      })
      .catch((err) => {
        setAlert("error", err.message);
      }).finally(() => {
        setIsSaving(false);
      });
  };

  const updateStudy = (newData) => {
    const apiHeader = {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    };
    axios
      .patch(GET_STUDIES_URL_BASE + "/" + newData.id, newData, apiHeader)
      .then((res) => {
        // index represents the sorted position of the selected study
        // before entering the CreateEditStudyForm
        let index = -1;
        if (state) {
          index = state.index;
        } else {
          // come to the page by refreshing
          index = studies.findIndex((studyObj) => studyObj.id === newData.id);
        }
        // after success save, navigate back to studydashboard
        history.push({ pathname: "/", highlightRow: index });
        // update existing study in study dashboard with changes
        let dataIndex = studies.findIndex(
          (studyObj) => studyObj.id === newData.id
        );
        let newStudies = [...studies];
        newStudies[dataIndex] = res.data;
        setStudies(newStudies);

        // Modify FiltersContext state to force refetch of filters panel data from backend
        setTriggerFiltersRefetch((prevState) => prevState + 1);
      }).catch((err) => {
        setAlert("error", err.message);
      }).finally(() => {
        setIsSaving(false);
      });
  };

  const handleCreateEditSaveStudy = () => {
    // validate and save
    setIsSaving(true);
    validateSaveAndSwitch(activeTab, true);
  };

  const getLoginHB = () => {
    if (isValidUser(currentUser)) {
      const result = allHonestBrokers.find(
        (contact) => contact.userName === currentUser.uid
      );
      if (result) {
        return result.id;
      }
    }
    return 0;
  };

  const handleChangeActiveTab = (evt, newValue) => {
    // validate and save the changes in the current tab to initData before proceed
    // react-hook-form getValues only return the registered controls
    // that is visible at the time
    validateSaveAndSwitch(newValue, false);
  };

  return createMode || !isEmpty(initData) ? (
    <Grid container className={classes.root}>
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        title={t("cancelConfirmation.title")}
        message={t("cancelConfirmation.message")}
        okLabel={t("cancelConfirmation.okLabel")}
        cancelLabel={t("cancelConfirmation.cancelLabel")}
      />
      <Grid item className={classes.customGridMargin} xs={12}>
        <Typography variant="h5" noWrap>
          {createMode ? t(`pageTitle.createStudy`) : t(`pageTitle.editStudy`)}
        </Typography>
      </Grid>
      <Grid item className={classes.customGridMargin} xs={12}>
        {!createMode && (
          <Typography>
            {"IRB#: "}{" "}
            <Typography variant="subtitle1" display="inline">
              {initData.irbNumber}
            </Typography>
          </Typography>
        )}
      </Grid>
      <Grid item className={classes.customGridMargin} xs={12}>
        <form onSubmit={handleSubmit(handleCreateEditSaveStudy)}>
          <CreateEditStudyMain
            createMode={createMode}
            disableCreate={disableCreate}
            setDisableCreate={setDisableCreate}
            loginHB={getLoginHB()}
            initData={initData}
            setInitData={setInitData}
            useAlertHook={useAlerts}
            Controller={Controller}
            hookcontrol={control}
            errors={errors}
            reset={reset}
            setIsOptimizationEnabled={setIsOptimizationEnabled}
          />
          <Paper className={classes.tabHeader}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeActiveTab}
            >
              <Tab label="Details" disabled={disableCreate} />
              <Tab label="Contacts" disabled={disableCreate} />
              <Tab label="Preferences" disabled={disableCreate} />
              {initData && !initData?.protocolSummaryAccrual ? (
                <Tab label="Target Accrual" disabled={disableCreate} />
              ) : (
                ""
              )}
            </Tabs>
          </Paper>
          <TabPanel
            className={classes.scrollableContainer}
            value={activeTab}
            index={0}
          >
            <StudyDetailsTab
              disableCreate={disableCreate}
              initData={initData}
              loginHB={getLoginHB()}
              Controller={Controller}
              hookcontrol={control}
              errors={errors}
            />
          </TabPanel>
          <TabPanel
            className={classes.scrollableContainer}
            value={activeTab}
            index={1}
          >
            <StudyContactsTab
              disableCreate={disableCreate}
              initData={initData}
              setInitData={setInitData}
              hookcontrol={control}
              errors={errors}
              crcError={crcError}
              validateLeadCrc={validateLeadCrc}
            />
          </TabPanel>
          <TabPanel
            className={classes.scrollableContainer}
            value={activeTab}
            index={2}
          >
            <StudyPreferencesTab
              disableCreate={disableCreate}
              initData={initData}
              setInitData={setInitData}
              Controller={Controller}
              hookcontrol={control}
              errors={errors}
            />
          </TabPanel>
          {initData && !initData?.protocolSummaryAccrual ? (
            <TabPanel value={activeTab} index={3}>
              <StudyTargetAccrualsTab
                disableCreate={disableCreate}
                initData={initData}
                setInitData={setInitData}
                Controller={Controller}
                hookcontrol={control}
                errors={errors}
                setBadTargetFields={setBadTargetFields}
                badTargetFields={badTargetFields}
                isOptimizationEnabled={isOptimizationEnabled}
                activeTab={activeTab}
              />
            </TabPanel>
          ) : (
            ""
          )}
          <Grid container spacing={3} item xs={12} justify="flex-end">
            <Grid container item xs={1} justify="flex-end">
              <Button variant="outlined" onClick={handleCancelCreate}>
                {t(`formLabel.buttonCancel`)}
              </Button>
            </Grid>
            <Grid container item xs={1} justify="flex-end">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={saveButtonDisableCheck}
              >
                {t(`formLabel.buttonSave`)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  ) : null;
};

export default CreateEditStudyForm;
