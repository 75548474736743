import { createContext, useContext, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { ProspectDefaults } from "app/shared/constants";
import { decodeBase64 } from "app/shared/utils";

export const ProspectContext = createContext();

export const ProspectProvider = ({ children }) => {
  // Getting location object from useLocation (react-router-dom)
  const location = useLocation();

  // State for filters
  const qs = queryString.parse(location.search);
  const [filters, setFilters] = useState({
    firstName: decodeBase64(qs.firstName),
    lastName: decodeBase64(qs.lastName),
    phone: decodeBase64(qs.phone),
    email: decodeBase64(qs.email),
    mrn: decodeBase64(qs.mrn),
    birthDate: decodeBase64(qs.birthDate),
    isOptedOut: decodeBase64(qs.isOptedOut),
    isHavingConcern: decodeBase64(qs.isHavingConcern),
  });

  const [initialLoad, setInitialLoad] = useState(true);

  // State for Sort
  const [sort, setSort] = useState({
    orderBy: ProspectDefaults.PROSPECTDEFAULTORDERBY,
    order: ProspectDefaults.PROSPECTDEFAULTORDER,
  });

  // State for pagination
  const [pagination, setPagination] = useState({
    page: ProspectDefaults.PROSPECTDEFAULTPAGE,
    rowsPerPage: ProspectDefaults.PROSPECTDEFAULTPAGESIZE,
  });

  return (
    <ProspectContext.Provider
      value={{
        filters,
        setFilters,
        initialLoad,
        setInitialLoad,
        sort,
        setSort,
        pagination,
        setPagination,
      }}
    >
      {children}
    </ProspectContext.Provider>
  );
};

export const useProspectContext = () => {
  return useContext(ProspectContext);
};
