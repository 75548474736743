import { AddCircleOutline as AddCircleOutlineIcon } from "@material-ui/icons";
import { Tooltip, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const StudyActionButtons = (props) => {
  return (
    <Grid container>
      <Grid container justify="flex-end" item xs>
        <Tooltip title="Create a Study" placement="top" arrow>
          <Link
            to={{
              pathname: "/studies/new",
              state: {
                createMode: true,
              },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
            >
              Create New
            </Button>
          </Link>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default StudyActionButtons;
