import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  headerCheckbox: {
    "&& span": {
      color: theme.palette.common.white,
    },
  },
  tableScroll: {
    margin: "16px 0px",
    "& .MuiTableContainer-root": {
      overflowX: "auto",
    },
    "& .MuiTableSortLabel-icon": {
      alignSelf: "center",
    },
    "& .MuiTableCell-stickyHeader": {
      zIndex: "0",
    },
  },
  tableCell: {
    "& .MuiTableCell-root": {
      verticalAlign: "top",
      wordBreak: "break-word",
    },
  },
  noteTableCell: {
    maxWidth: "100px",
  },
  nicknameTableCell: {
    minWidth: "250px",
  },
  highlightRow: {
    backgroundColor: "#FED8B1",
  },
  studyNote: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "12px",
  },
  lightTooltipArrow: {
    color: theme.palette.common.white,
  },
  riskContainer: {
    display: "flex",
    "& .riskcount": {
      color: theme.palette.error.main,
      fontWeight: "bold",
      marginTop: "4px",
    },
    "& .warningIcon": {
      color: theme.palette.warning.main,
    },
  },
}));
