import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  labelWrapper: {
    whiteSpace: "nowrap",
  },
  customHeaderPadding: {
    padding: "15px",
  },
  customAutocompletePadding: {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
  },
  datePicker: {
    marginTop: "20px",
  },
}));
