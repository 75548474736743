import { useState, useEffect } from "react";
import { useStyles } from "app/study/StudyFiltersStyles";
import {
  TextField,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  FormLabel,
  Radio,
  FormControlLabel,
  Divider,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  RecruitmentStatusOptions,
  IrbStatusOptions,
  MultiSite,
  defaultFUDateFrom,
  defaultDateTo,
  defaultRecruitmentDateFrom,
  StudyDefaults,
  SummaryAccrual,
  CancerNonCancer,
} from "app/shared/constants";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Loop as LoopIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useFilters } from "app/services/filterService";
import { useUsers } from "common";
import { useStudies } from "app/services/studiesService";
import { setDefaultHB } from "app/shared/utils";
import { useHistory } from "react-router-dom";
import PEChipContainer from "app/shared/UI/PEChipContainer";
import PEChip from "app/shared/UI/PEChip";
import AntSwitch from "app/shared/UI/AntSwitch";
import PERadioGroup from "app/shared/UI/PERadioGroup";
import { encodeBase64 } from "app/shared/utils";

const defaultStudyStatus = [
  "NOT_YET_RECRUITING",
  "RECRUITING",
  "ACTIVE_NOT_RECRUITING",
];

const getReStatus = (studyFilters) =>
  studyFilters.reStatus ? studyFilters.reStatus.split(",") : null;

const getCancer = (studyFilters) =>
  studyFilters.cancer ? studyFilters.cancer : "all";

const getProtocolSummaryAccrual = (studyFilters) =>
  studyFilters.protocolSummaryAccrual
    ? studyFilters.protocolSummaryAccrual
    : "all";

const getPtlStatus = (studyFilters) =>
  studyFilters.ptlStatus ? studyFilters.ptlStatus.split(",") : null;

const getProtocolMultisiteTrial = (studyFilters) =>
  studyFilters.protocolMultisiteTrial
    ? studyFilters.protocolMultisiteTrial
    : "all";

const StudyFilterForm = (props) => {
  let history = useHistory();
  const {
    studyFilters,
    setStudyFilters,
    studiesFilterLoader,
    setStudiesFilterLoader,
  } = useStudies();
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const {
    contacts,
    honestBrokers,
    studyIrbNumbers,
    studyNicknames,
    studyDepts,
    studyDivisions,
  } = useFilters();

  const classes = useStyles();

  // hooks, save initial value at state and setter
  const [irbNumber, setIrbNumber] = useState(studyFilters.irbNumber);
  const [nickname, setNickname] = useState(studyFilters.nickname);
  const [reStatus, setReStatus] = useState(getReStatus(studyFilters));
  const [cancer, setCancer] = useState(getCancer(studyFilters));

  const [honestBroker, setHonestBroker] = useState(studyFilters.hbId);

  useEffect(() => {
    if (honestBrokers.length) {
      setHonestBroker((honestBroker) =>
        honestBroker === null
          ? setDefaultHB(honestBrokers, currentUser)
          : honestBroker
      );
    }
  }, [honestBrokers, currentUser]);

  const [protocolSummaryAccrual, setProtocolSummaryAccrual] = useState(
    getProtocolSummaryAccrual(studyFilters)
  );
  const [protocolMultisiteTrial, setProtocolMultisiteTrial] = useState(
    getProtocolMultisiteTrial(studyFilters)
  );
  const [ptlStatus, setPtlStatus] = useState(getPtlStatus(studyFilters));

  const [followUpDateFrom, setFollowUpDateFrom] = useState(
    studyFilters.fuDateFrom || null
  );
  const [minFUDateFrom] = useState(defaultFUDateFrom);
  const [maxFUDateFrom, setMaxFUDateFrom] = useState(defaultDateTo);
  const [followUpDateTo, setFollowUpDateTo] = useState(
    studyFilters.fuDateTo || null
  );
  const [minFUDateTo, setMinFUDateTo] = useState(defaultFUDateFrom);
  const [maxFUDateTo] = useState(defaultDateTo);

  const [suspended, setSuspended] = useState(studyFilters.suspended);
  const [selectedContact, setSelectedContact] = useState(
    studyFilters.contactId
  );

  const [department, setDepartment] = useState(studyFilters.department);
  const [division, setDivision] = useState(studyFilters.division);

  const [recruitmentStartDateFrom, setRecruitmentStartDateFrom] = useState(
    studyFilters.reStartDateFrom || null
  );
  const [minReStartDateFrom] = useState(defaultRecruitmentDateFrom);
  const [maxReStartDateFrom, setMaxReStartDateFrom] = useState(defaultDateTo);

  const [recruitmentStartDateTo, setRecruitmentStartDateTo] = useState(
    studyFilters.reStartDateTo || null
  );
  const [minReStartDateTo, setMinReStartDateTo] = useState(
    defaultRecruitmentDateFrom
  );
  const [maxReStartDateTo] = useState(defaultDateTo);

  const [recruitmentEndDateFrom, setRecruitmentEndDateFrom] = useState(
    studyFilters.reEndDateFrom || null
  );
  const [minReEndDateFrom] = useState(defaultRecruitmentDateFrom);
  const [maxReEndDateFrom, setMaxReEndDateFrom] = useState(defaultDateTo);

  const [recruitmentEndDateTo, setRecruitmentEndDateTo] = useState(
    studyFilters.reEndDateTo || null
  );
  const [minReEndDateTo, setMinReEndDateTo] = useState(
    defaultRecruitmentDateFrom
  );
  const [maxReEndDateTo] = useState(defaultDateTo);

  const handleFollowUpDateFromChange = (date) => {
    setFollowUpDateFrom(date);
    // change Follow-up-date-to minFUDate to make sure date-to is always after date-from
    setMinFUDateTo(date);
  };

  const handleFollowUpDateToChange = (date) => {
    setFollowUpDateTo(date);
    // change Follow-up-date-from maxFUDate to make sure date-from is always before date-to
    setMaxFUDateFrom(date);
  };

  const handleSuspendedChange = (evt) => {
    setSuspended(evt.target.checked);
  };

  const handleRecruitmentStartDateFromChange = (date) => {
    setRecruitmentStartDateFrom(date);
    // use the date as date-to's minDate
    setMinReStartDateTo(date);
  };

  const handleRecruitmentStartDateToChange = (date) => {
    setRecruitmentStartDateTo(date);
    // use the date to be maxDate of recruitmentStartDate date-from
    setMaxReStartDateFrom(date);
  };

  const handleRecruitmentEndDateFromChange = (date) => {
    setRecruitmentEndDateFrom(date);
    // use the date to be minDate of recruitmentStartDate date-to
    setMinReEndDateTo(date);
  };

  const handleRecruitmentEndDateToChange = (date) => {
    setRecruitmentEndDateTo(date);
    setMaxReEndDateFrom(date);
  };

  const handleResetStudyFilters = () => {
    // reset filters to default one
    setIrbNumber(null);
    setNickname(null);
    setFollowUpDateFrom(null);
    setFollowUpDateTo(null);
    setReStatus(defaultStudyStatus);
    setSuspended(false);
    setSelectedContact(null);
    setDepartment(null);
    setDivision(null);
    setCancer("all");
    setHonestBroker(setDefaultHB(honestBrokers, currentUser));
    setProtocolSummaryAccrual("all");
    setProtocolMultisiteTrial("all");
    setPtlStatus(null);
    setRecruitmentStartDateFrom(null);
    setRecruitmentStartDateTo(null);
    setRecruitmentEndDateFrom(null);
    setRecruitmentEndDateTo(null);

    setStudyFilters({
      reStatus: StudyDefaults.STUDYDEFAULTSTATUS.join(","),
      suspended: StudyDefaults.STUDYDEFAULTSUSPENDED,
      hbId: setDefaultHB(honestBrokers, currentUser),
    });
  };

  const handleApplyStudyFilters = () => {
    setStudiesFilterLoader(true);
    let studyFiltersToSave = {};

    // irbNumber
    if (irbNumber !== null) {
      studyFiltersToSave.irbNumber = irbNumber;
    }

    // nickname
    if (nickname !== null) {
      studyFiltersToSave.nickname = nickname;
    }

    // fuDateFrom, fuDateTo
    if (followUpDateFrom || followUpDateTo) {
      let fuDateFrom = null;
      let fuDateTo = null;
      if (followUpDateFrom && followUpDateTo) {
        fuDateFrom = followUpDateFrom;
        fuDateTo = followUpDateTo;
      } else if (followUpDateFrom) {
        fuDateFrom = followUpDateFrom;
        fuDateTo = defaultDateTo;
      } else {
        fuDateFrom = defaultFUDateFrom;
        fuDateTo = followUpDateTo;
      }

      studyFiltersToSave.fuDateFrom = fuDateFrom;
      studyFiltersToSave.fuDateTo = fuDateTo;
    }
    // reStatus, return the status code
    if (reStatus != null && reStatus.length > 0) {
      studyFiltersToSave.reStatus = reStatus.join(",");
    }

    // suspended
    studyFiltersToSave.suspended = suspended;

    // contactId
    if (selectedContact != null) {
      studyFiltersToSave.contactId = selectedContact;
    }

    // department
    if (department) {
      studyFiltersToSave.dept = department;
    }

    // division
    if (division) {
      studyFiltersToSave.division = division;
    }

    // cancer
    if (cancer !== "all") {
      studyFiltersToSave.cancer = cancer;
    }
    // hbId
    if (honestBroker) {
      studyFiltersToSave.hbId = honestBroker;
    }
    // source
    if (protocolMultisiteTrial !== "all") {
      studyFiltersToSave.protocolMultisiteTrial = protocolMultisiteTrial;
    }
    if (protocolSummaryAccrual !== "all") {
      studyFiltersToSave.protocolSummaryAccrual = protocolSummaryAccrual;
    }
    // ptlStatus
    if (ptlStatus != null && ptlStatus.length > 0) {
      studyFiltersToSave.ptlStatus = ptlStatus.join(",");
    }
    // reStartDateFrom, reStartDateTo
    if (recruitmentStartDateFrom || recruitmentStartDateTo) {
      let reStartDateFrom = null;
      let reStartDateTo = null;
      if (recruitmentStartDateFrom && recruitmentStartDateTo) {
        reStartDateFrom = recruitmentStartDateFrom;
        reStartDateTo = recruitmentStartDateTo;
      } else if (recruitmentStartDateFrom) {
        reStartDateFrom = recruitmentStartDateFrom;
        reStartDateTo = defaultDateTo;
      } else {
        reStartDateFrom = defaultRecruitmentDateFrom;
        reStartDateTo = recruitmentStartDateTo;
      }
      studyFiltersToSave.reStartDateFrom = reStartDateFrom;
      studyFiltersToSave.reStartDateTo = reStartDateTo;
    }

    // reEndDateFrom, reEndDateTo
    if (recruitmentEndDateFrom || recruitmentEndDateTo) {
      let reEndDateFrom = null;
      let reEndDateTo = null;
      if (recruitmentEndDateFrom && recruitmentEndDateTo) {
        reEndDateFrom = recruitmentEndDateFrom;
        reEndDateTo = recruitmentEndDateTo;
      } else if (recruitmentEndDateFrom) {
        reEndDateFrom = recruitmentEndDateFrom;
        reEndDateTo = defaultDateTo;
      } else {
        reEndDateFrom = defaultRecruitmentDateFrom;
        reEndDateTo = recruitmentEndDateTo;
      }
      studyFiltersToSave.reEndDateFrom = reEndDateFrom;
      studyFiltersToSave.reEndDateTo = reEndDateTo;
    }

    // this should trigger featch new studies and StudyDashboard to refresh, but didn't???
    setStudyFilters(studyFiltersToSave);
  };

  useEffect(() => {
    const params = {
      reStatus,
      suspended,
      honestBroker,
      irbNumber,
      nickname,
      followUpDateFrom,
      followUpDateTo,
      selectedContact,
      department,
      division,
      cancer,
      protocolMultisiteTrial,
      ptlStatus,
      recruitmentStartDateFrom,
      recruitmentStartDateTo,
      recruitmentEndDateFrom,
      recruitmentEndDateTo,
      protocolSummaryAccrual,
    };
    const queryParams = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => {
        return `${key}=${encodeBase64(params[key])}`;
      })
      .join("&");
    history.push(`${history.location.pathname}?${queryParams}`);
  }, [
    cancer,
    department,
    division,
    followUpDateFrom,
    followUpDateTo,
    history,
    honestBroker,
    irbNumber,
    nickname,
    ptlStatus,
    reStatus,
    recruitmentEndDateFrom,
    recruitmentEndDateTo,
    recruitmentStartDateFrom,
    recruitmentStartDateTo,
    selectedContact,
    protocolMultisiteTrial,
    suspended,
    protocolSummaryAccrual,
  ]);

  return (
    <Grid container>
      <Grid className={classes.customHeaderPadding} item xs={12}>
        <Typography variant="h6" noWrap>
          {t("formLabel.studyFilterTitle")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container item xs={12} justify="flex-end">
        <Button startIcon={<LoopIcon />} onClick={handleResetStudyFilters}>
          {t("formLabel.buttonResetFilter")}
        </Button>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyStudyFilters}
            disabled={studiesFilterLoader}
          >
            {t("formLabel.buttonApplyFilter")}
          </Button>
          {studiesFilterLoader && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="irb-number"
          options={studyIrbNumbers}
          getOptionLabel={(option) => option}
          value={irbNumber}
          onChange={(evt, newValue) => setIrbNumber(newValue)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={t("formLabel.irbNumberFilterLabel")}
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("formLabel.irbNumberFilterLabel")}
              />
            );
          }}
        />
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="study-nickname"
          options={studyNicknames}
          getOptionLabel={(option) => option}
          value={nickname}
          onChange={(evt, newValue) => setNickname(newValue)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={t("formLabel.studyNickname")}
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("formLabel.studyNickname")}
              />
            );
          }}
        />
      </Grid>
      <Grid
        container
        className={classes.customAutocompletePadding}
        item
        xs={12}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label={t("formLabel.followUpDateFrom")}
              format="MM/dd/yyyy"
              margin="dense"
              id="follow-up-date-start"
              value={followUpDateFrom}
              minDate={minFUDateFrom}
              maxDate={maxFUDateFrom}
              onChange={handleFollowUpDateFromChange}
            />
          </Grid>
          <Grid container justify="center" item xs={1}>
            <span className={classes.datePicker}>{" - "}</span>
          </Grid>
          <Grid item xs>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label={t("formLabel.followUpDateTo")}
              format="MM/dd/yyyy"
              margin="dense"
              id="follow-up-date-end"
              value={followUpDateTo}
              minDate={minFUDateTo}
              maxDate={maxFUDateTo}
              onChange={handleFollowUpDateToChange}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="recruitment-status" shrink={true}>
            {t("formLabel.recruitmentStatusFilterLabel")}
          </InputLabel>
          <Select
            value={reStatus || []}
            onChange={(e) => setReStatus(e.target.value)}
            multiple
            input={
              <OutlinedInput
                id="recruitment-status"
                name="recruitment-status"
                label={t("formLabel.recruitmentStatusFilterLabel")}
                margin="dense"
                notched
              />
            }
            renderValue={(selected) => (
              <PEChipContainer>
                {selected.map((value) => (
                  <PEChip key={value} label={t("recruitmentStatus." + value)} />
                ))}
              </PEChipContainer>
            )}
          >
            {RecruitmentStatusOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {t("recruitmentStatus." + item)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <Typography component="div">
          <FormLabel component="legend">{t("formLabel.suspended")}</FormLabel>
          <Grid
            id="suspendedSwitch"
            component="label"
            container
            alignItems="center"
            spacing={1}
          >
            <Grid item>{t("formLabel.switchNo")}</Grid>
            <Grid item>
              <AntSwitch
                checked={suspended}
                onChange={handleSuspendedChange}
                value="suspended"
              />
            </Grid>
            <Grid item>{t("formLabel.switchYes")}</Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="contact-name"
          options={contacts.map((c) => c.id)}
          getOptionLabel={(contactId) => {
            // we only keep the contact ids as 'options'. To display the actual
            // value we resolve it by looking at the 'contacts' list
            const contactById = contacts.find((c) => c.id === contactId);
            return contactById.firstName + " " + contactById.lastName;
          }}
          value={selectedContact}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("formLabel.contactName")}
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={t("formLabel.contactName")}
            />
          )}
          onChange={(evt, newValue) => setSelectedContact(newValue)}
        />
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="study-dept"
          options={studyDepts}
          getOptionLabel={(option) => option}
          value={department}
          onChange={(evt, newValue) => setDepartment(newValue)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={t("formLabel.department")}
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("formLabel.department")}
              />
            );
          }}
        />
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <Autocomplete
          id="study-division"
          options={studyDivisions}
          getOptionLabel={(option) => option}
          value={division}
          onChange={(evt, newValue) => setDivision(newValue)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={t("formLabel.divisionFilterLabel")}
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("formLabel.divisionFilterLabel")}
              />
            );
          }}
        />
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <FormControl fullWidth component="fieldset">
          <FormLabel component="legend">
            {t("formLabel.cancerCenterFilterLabel")}
          </FormLabel>
          <PERadioGroup
            name="cancer-non-cancer"
            value={cancer}
            onChange={(e) => setCancer(e.target.value)}
          >
            <FormControlLabel
              value={CancerNonCancer.All}
              control={<Radio />}
              label={t("formLabel.cancerCenterAll")}
            />
            <FormControlLabel
              value={CancerNonCancer.Cancer}
              control={<Radio />}
              label={t("formLabel.cancerCenterCancer")}
            />
            <FormControlLabel
              value={CancerNonCancer.NonCancer}
              control={<Radio />}
              label={t("formLabel.cancerCenterNon")}
            />
          </PERadioGroup>
        </FormControl>
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="honest-broker"
            select
            value={honestBroker}
            onChange={(e) => setHonestBroker(e.target.value)}
            label={t("formLabel.assignedHonestBroker")}
            placeholder={t("formLabel.assignedHonestBroker")}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="">{t("formLabel.none")}</MenuItem>
            {honestBrokers &&
              honestBrokers.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.displayName}
                </MenuItem>
              ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <FormControl fullWidth component="fieldset">
          <FormLabel component="legend">{t("formLabel.multiSite")}</FormLabel>
          <PERadioGroup
            name="multi-site"
            value={protocolMultisiteTrial}
            onChange={(e) => setProtocolMultisiteTrial(e.target.value)}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label={t("formLabel.multiSiteAll")}
            />
            <FormControlLabel
              value={MultiSite.MultiSiteFalse}
              control={<Radio />}
              label={t("formLabel.multiSiteStanford")}
            />
            <FormControlLabel
              value={MultiSite.MultiSiteTrue}
              control={<Radio />}
              label={t("formLabel.multiSite")}
            />
          </PERadioGroup>
        </FormControl>
      </Grid>
      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="irb-status" shrink={true} variant="outlined">
            {t("formLabel.irbStatus")}
          </InputLabel>
          <Select
            value={ptlStatus || []}
            onChange={(e) => setPtlStatus(e.target.value)}
            multiple
            input={
              <OutlinedInput
                id="irb-status"
                label={t("formLabel.irbStatus")}
                labelWidth={1}
                margin="dense"
                placeholder={t("formLabel.irbStatus")}
                notched
              />
            }
            renderValue={(selected) => (
              <PEChipContainer>
                {selected.map((value) => (
                  <PEChip key={value} label={t("irbStatus." + value)} />
                ))}
              </PEChipContainer>
            )}
          >
            {IrbStatusOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {t("irbStatus." + item)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid className={classes.customAutocompletePadding} item xs={12}>
        <FormControl fullWidth component="fieldset">
          <FormLabel component="legend">
            {t("formLabel.summaryAccrual")}
          </FormLabel>
          <PERadioGroup
            name="protocolSummaryAccrual"
            value={protocolSummaryAccrual}
            onChange={(e) => setProtocolSummaryAccrual(e.target.value)}
          >
            <FormControlLabel
              value="all"
              control={<Radio />}
              label={t("formLabel.protocolSummaryAccrualAll")}
            />
            <FormControlLabel
              value={SummaryAccrual.SummaryAccrualTrue}
              control={<Radio />}
              label={t("formLabel.protocolSummaryAccrualYes")}
            />
            <FormControlLabel
              value={SummaryAccrual.SummaryAccrualFalse}
              control={<Radio />}
              label={t("formLabel.protocolSummaryAccrualNo")}
            />
          </PERadioGroup>
        </FormControl>
      </Grid>
      <Grid
        container
        className={classes.customAutocompletePadding}
        item
        xs={12}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.labelWrapper}
              label={t("formLabel.recruitmentStartDateFrom")}
              format="MM/dd/yyyy"
              margin="dense"
              id="recruitment-start-date-from"
              value={recruitmentStartDateFrom}
              minDate={minReStartDateFrom}
              maxDate={maxReStartDateFrom}
              onChange={handleRecruitmentStartDateFromChange}
            />
          </Grid>
          <Grid container justify="center" item xs={1}>
            <span className={classes.datePicker}>{" - "}</span>
          </Grid>
          <Grid item xs>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label={t("formLabel.recruitmentStartDateTo")}
              format="MM/dd/yyyy"
              margin="dense"
              id="recruitment-start-date-to"
              value={recruitmentStartDateTo}
              minDate={minReStartDateTo}
              maxDate={maxReStartDateTo}
              onChange={handleRecruitmentStartDateToChange}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid
        container
        className={classes.customAutocompletePadding}
        item
        xs={12}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.labelWrapper}
              label={t("formLabel.recruitmentEndDateFrom")}
              format="MM/dd/yyyy"
              margin="dense"
              id="recruitment-end-date-from"
              value={recruitmentEndDateFrom}
              minDate={minReEndDateFrom}
              maxDate={maxReEndDateFrom}
              onChange={handleRecruitmentEndDateFromChange}
            />
          </Grid>
          <Grid container justify="center" item xs={1}>
            <span className={classes.datePicker}>{" - "}</span>
          </Grid>
          <Grid item xs>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label={t("formLabel.recruitmentEndDateTo")}
              format="MM/dd/yyyy"
              margin="dense"
              id="recruitment-end-date-to"
              value={recruitmentEndDateTo}
              minDate={minReEndDateTo}
              maxDate={maxReEndDateTo}
              onChange={handleRecruitmentEndDateToChange}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid container item xs={12} justify="flex-end">
        <Button startIcon={<LoopIcon />} onClick={handleResetStudyFilters}>
          {t("formLabel.buttonResetFilter")}
        </Button>
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyStudyFilters}
            disabled={studiesFilterLoader}
          >
            {t("formLabel.buttonApplyFilter")}
          </Button>
          {studiesFilterLoader && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default StudyFilterForm;
