import { useState } from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormLabel,
  TextField,
  Button,
} from "@material-ui/core";
import AntSwitch from "app/shared/UI/AntSwitch";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useStyles } from "app/study/CreateEditStudyFormStyles";
import {
  defaultFUDateFrom,
  defaultDateTo,
  GET_STUDIES_URL_BASE,
  GET_PROTOCOLS_URL_BASE,
  RecruitmentStatusOptions,
} from "app/shared/constants";
import { mapStudyToDisplayed } from "app/shared/utils";
import { format, isPast, isValid } from "date-fns";
import WarningDialog from "app/shared/UI/PEWarningDialog";

const CreateEditStudyMain = (props) => {
  const {
    createMode,
    disableCreate,
    setDisableCreate,
    loginHB,
    initData,
    setInitData,
    useAlertHook,
    Controller,
    hookcontrol,
    errors,
    reset,
    setIsOptimizationEnabled
  } = props;
  const { t } = useTranslation();
  const { setAlert } = useAlertHook();
  const classes = useStyles();
  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const [invalidIRB, setInvalidIRB] = useState({
    invalid: false,
    message: null,
  });

  const handleCheckAvailability = () => {
    // manually check if irbNumber field is set and valid
    const values = hookcontrol.getValues();
    const regex = RegExp(/\b\d{5}\b/g);
    const irbNumber = values.irbNumber;
    if (!irbNumber) {
      setInvalidIRB({
        invalid: true,
        message: t(`formValidation.requiredField`),
      });
      return;
    } else if (!regex.test(irbNumber)) {
      setInvalidIRB({
        invalid: true,
        message: t(`formValidation.invalidIRBNumber`),
      });
      return;
    }
    searchStudyByIRB(irbNumber);
  };

  const searchStudyByIRB = (irbNumber) => {
    axios
      .get(GET_STUDIES_URL_BASE + "?irbNumber=" + irbNumber)
      .then((res) => {
        if (res.data.length > 0) {
          // display an error msg so user need to choose another irb number
          setInvalidIRB({
            invalid: true,
            message: t(`formValidation.irbNumberExist`),
          });
        } else {
          // proceed to call API to check availability
          // if available, enable the rest of form
          getProtocolByIRB(irbNumber); // this is a valid IRB number for now
        }
      })
      .catch((err) => {
        setAlert("error", err.message);
      });
  };

  const getProtocolByIRB = (irbNumber) => {
    axios
      .get(GET_PROTOCOLS_URL_BASE + irbNumber)
      .then((res) => {
        // irb number is available for creating a study
        // disable IRBNumver and Check Availability button, enable the rest of form
        setInvalidIRB({ invalid: false, message: null });
        setDisableCreate(false);
        const mappedStudy = mapStudyToDisplayed(res.data);
        setInitData({ ...mappedStudy, ...{ honestBroker: { id: loginHB } } });
        // reset the registered editable controls
        reset({
          irbNumber: irbNumber,
          nickName: res.data.protocolShortTitle,
          title: res.data.protocolTitle,
        });
      })
      .catch((err) => {
        // handle uncommon server errors
        if (err.status === 400) {
          setInvalidIRB({
            invalid: true,
            message: t(`formValidation.irbNumberNotValid`, {
              irbnumber: irbNumber,
            }),
          });
        } else if (err.status === 404) {
          setInvalidIRB({
            invalid: true,
            message: t(`formValidation.irbNumberNotExistInOnCore`),
          });
        } else {
          setAlert("error", err.message);
        }
      });
  };

  const handleFollowUpDateValidation = (date) => {
    // Validation for adding new study
    if (createMode) {
      return format(new Date(date), "MM/dd/yyyy") !==
        format(new Date(), "MM/dd/yyyy") //this condition is to not trigger validation on today's date.
        ? !isPast(new Date(date))
          ? setOpenASConfirmation(false)
          : setOpenASConfirmation(true)
        : true;
    }
    // Validation for updating a study
    return format(new Date(date), "MM/dd/yyyy") !==
      format(new Date(), "MM/dd/yyyy") &&
      initData.followUpDate.getTime() !== date.getTime() //this condition is to not trigger validation on initial followup date & today's date.
      ? !isPast(new Date(date))
        ? setOpenASConfirmation(false)
        : setOpenASConfirmation(true)
      : true;
  };
  const closeCancelASConfirmation = () => {
    setOpenASConfirmation(false);
  };

  return (
    <>
      <WarningDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        title={t("formValidation.followUpDateTitle")}
        primaryMessage={t("formValidation.followUpDate")}
        secondaryMessage={t("formValidation.secondaryFollowUpDate")}
      />
      <Grid container justify="space-between" spacing={2}>
        {createMode && (
          <>
            <Grid item xs={6}>
              <TextField
                name="irbNumber"
                fullWidth
                label={t(`formLabel.irbNumber`)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={hookcontrol.register({
                  pattern: /(\d{5})/g,
                  required: true,
                })}
                size="small"
                disabled={!disableCreate}
                error={invalidIRB.invalid}
                helperText={invalidIRB.message}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCheckAvailability}
                disabled={!disableCreate}
              >
                {t(`formLabel.buttonCheckAvailability`)}
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextField
            name="nickName"
            fullWidth
            label={t(`formLabel.studyNickname`)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register}
            disabled={disableCreate}
            size="small"
            defaultValue={initData.nickname}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="title"
            fullWidth
            label={t(`formLabel.studyTitle`)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register({ required: true })}
            disabled={disableCreate}
            size="small"
            error={errors.title ? true : false}
            helperText={errors.title && t("formValidation.requiredField")}
            defaultValue={initData.title}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="oncoreStatus"
            fullWidth
            label={t(`formLabel.oncoreProtocolStatus`)}
            variant="outlined"
            value={initData.protocolStatus || ""}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="oncoreProtocolNumber"
            fullWidth
            label={t(`formLabel.oncoreProtocolNumber`)}
            variant="outlined"
            value={initData.protocolNumber || ""}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            disabled
          />
        </Grid>
        {!createMode && (
          <>
            <Grid item xs={3}>
              <TextField
                name="riskCount"
                fullWidth
                label={t(`formLabel.riskCount`)}
                variant="outlined"
                value={initData.riskCount || 0}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="recruited"
                fullWidth
                label={t(`formLabel.recruited`)}
                variant="outlined"
                value={
                  initData.recruited +
                  " (" +
                  initData.subjectSummary.enrolled +
                  "/" +
                  initData.protocolTargetAccrual +
                  ")"
                }
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <Typography component="div">
                <FormLabel component="legend" style={{ marginBottom: 5 }}>
                  {t("formLabel.suspended")}
                </FormLabel>
                <Grid
                  id="suspendedSwitch"
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>{t("formLabel.switchNo")}</Grid>
                  <Grid item>
                    <Controller
                      render={({ onChange, value }) => (
                        <AntSwitch
                          checked={value}
                          onChange={(e) => {
                            // onChange's arg will send value into hook form
                            onChange(e.target.checked);
                          }}
                        />
                      )}
                      name="suspended"
                      control={hookcontrol}
                      defaultValue={initData.suspended}
                    />
                  </Grid>
                  <Grid item>{t("formLabel.switchYes")}</Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Controller
                as={
                  <TextField
                    select
                    fullWidth
                    label={t(`formLabel.recruitmentStatus`)}
                    variant="outlined"
                    size="small"
                    disabled={disableCreate}
                    error={errors.recruitmentStatus ? true : false}
                    helperText={
                      errors.recruitmentStatus &&
                      t("formValidation.requiredField")
                    }
                  >
                    <MenuItem value={0}>{t("formLabel.none")}</MenuItem>
                    {RecruitmentStatusOptions &&
                      RecruitmentStatusOptions.map((item) => (
                        <MenuItem key={item} value={item}>
                          {t("recruitmentStatus." + item)}
                        </MenuItem>
                      ))}
                  </TextField>
                }
                name="recruitmentStatus"
                control={hookcontrol}
                rules={{ required: true }}
                defaultValue={initData.honestBrokerRecruitmentStatus || 0}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              render={({ onChange, value }) => (
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  fullWidth
                  variant="inline"
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="dense"
                  className={classes.datepicker}
                  format="MM/dd/yyyy"
                  label={t(`formLabel.followUpDate`)}
                  minDate={defaultFUDateFrom}
                  maxDate={defaultDateTo}
                  disabled={disableCreate}
                  value={value}
                  onChange={(date) => {
                    if (isValid(new Date(date))) {
                      handleFollowUpDateValidation(date);
                    }
                    onChange(date);
                  }}
                />
              )}
              name="followUpDate"
              control={hookcontrol}
              defaultValue={
                initData.followUpDate
                  ? new Date(initData.followUpDate)
                  : new Date(Date.now() + 12096e5)
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              fullWidth
              variant="inline"
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              margin="dense"
              className={classes.datepicker}
              format="MM/dd/yyyy"
              label={t(`formLabel.irbExpirationDate`)}
              value={
                initData.protocolIrbExpirationDate
                  ? initData.protocolIrbExpirationDate
                  : null
              }
              disabled
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} className={classes.customGridMargin}>
          <TextField
            name="studyNote"
            fullWidth
            label={t(`formLabel.note`)}
            variant="outlined"
            inputRef={hookcontrol.register}
            disabled={disableCreate}
            multiline={true}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            defaultValue={initData.note}
          />
        </Grid>

        { initData && !initData.protocolSummaryAccrual ?
        <Grid item xs={6}>
          <Typography component="div">
            <FormLabel component="legend" style={{ marginBottom: 5 }}>
              {t("targetAccrualsTable.subpopulation_optimization_label")}
            </FormLabel>
            <Grid
              id="optimizationSwitch"
              component="label"
              container
              alignItems="center"
              spacing={1}
            >
              <Grid item>{t("formLabel.switchNo")}</Grid>
                  <Grid item>
                    <Controller
                      render={({ onChange, value }) => (
                        <AntSwitch
                          checked={value}
                          onChange={(e) => {
                            // onChange's arg will send value into hook form
                            onChange(e.target.checked);
                            setIsOptimizationEnabled(e.target.checked);
                          }}
                          disabled={initData.protocolSummaryAccrual}
                        />
                      )}
                      name="targetAccruals.isOptimizationEnabled"
                      control={hookcontrol}
                      defaultValue={!initData.protocolSummaryAccrual && !!initData.targetAccruals?.isOptimizationEnabled}
                    />
                  </Grid>
              <Grid item>{t("formLabel.switchYes")}</Grid>
            </Grid>
          </Typography>
        </Grid>
        : "" }
      </Grid>
    </>
  );
};

export default CreateEditStudyMain;
