// server api URLs
export const GET_STUDIES_URL_BASE = `/studies`;
export const GET_STUDIES_URL_PAGINATION_PARAMS = `&p=1&ps=${process.env.REACT_APP_PAGE_SIZE}`;
export const GET_STUDY_CONTACTS_URL = GET_STUDIES_URL_BASE + `/all/contacts`;
export const GET_STUDY_HBUSERS_URL = GET_STUDIES_URL_BASE + `/all/hbusers`;
export const GET_STUDY_IRBNUMBERS_URL =
  GET_STUDIES_URL_BASE + `/all/irbNumbers`;
export const GET_STUDY_NICKNAMES_URL = GET_STUDIES_URL_BASE + `/all/nicknames`;
export const GET_STUDY_DEPTS_URL = GET_STUDIES_URL_BASE + `/all/departments`;
export const GET_STUDY_DIVISIONS_URL = GET_STUDIES_URL_BASE + `/all/divisions`;
export const GET_BENCHMARKS_URL = GET_STUDIES_URL_BASE + `/benchmarks`;

export const GET_USERS_ME_URL = `/users/me`;
export const GET_ALL_HBUSERS_URL = `/users/hbpersons`;
export const GET_THERAPEUTICAREAS_URL = `/therapeuticareas`;

export const GET_PROTOCOLS_URL_BASE = `/protocols/`;

export const GET_IMPORTS_URL_BASE = `/imports`;
export const GET_FUNDINGSOURCES_URL_BASE = `/fundingsources`;

export const URLs = {
  GET_OUTREACH_URL_BASE: `/outreaches`,
  GET_OUTREACH_PROSPECT_COUNTS_URL_BASE: `/outreaches/prospects/counts`,
  GET_OUTREACH_HISTORY_URL_BASE: `/outreaches/study`,
  GET_OUTREACH_STATISTICS_URL_BASE: `/statistics`,
  GET_OUTREACH_CANCEL_URL_BASE: `/cancel`,
  GET_OUTREACHABLE_URL_BASE: `/imports/outreachable`,
  GET_OUTREACH_CHANNEL_URL_BASE: `/outreaches/channel`,
  PUT_OUTREACH_MATRIX_URL_BASE: `/summaryStatistics`,
  GET_STUDY_LISTING_ERROR_SUMMARY: `/errorSummary`,
  GET_OUTREACH_RECURRING_SCHEDULE_URL_BASE: `/schedule`,
};

// study status options
export const RecruitmentStatus = {
  PREACTIVE: "NOT_YET_RECRUITING",
  RECRUITING: "RECRUITING",
  POSTACTIVE: "ACTIVE_NOT_RECRUITING",
  COMPLETED: "COMPLETED",
  TERMINATED: "TERMINATED",
  WITHDRAWN: "WITHDRAWN",
};

// study contact role
export const StudyContactRole = {
  PI: "PRINCIPAL_INVESTIGATOR",
  PRIMARY_CRC: "PRIMARY_CRC",
};

export const RecruitmentStatusOptions = [
  `NOT_YET_RECRUITING`,
  `RECRUITING`,
  `ACTIVE_NOT_RECRUITING`,
  `COMPLETED`,
  `TERMINATED`,
  `WITHDRAWN`,
];

export const IrbStatusOptions = [
  `NEW`,
  `IRB_INITIAL_APPROVAL`,
  `OPEN_TO_ACCRUAL`,
  `CLOSED_TO_ACCRUAL`,
  `SUSPENDED`,
];

export const StudyEntities = ["LPCH", "PCHA", "SHC", "UHA", "VC"];

export const StudyChannelOptions = {
  DE: "DE",
  MC: "MC",
  MH: "MH",
  MN: "MN",
};

export const RecruitmentPreferences = [
  "OR_ENABLE_CHANNELS",
  "CH_INCLUDE_ENTITIES",
  "OR_NO_CONTACT_PROSPECT_WITHIN_X_DAYS",
  "OR_PROSPECT_ACCOUNT_LAST_ACCESS_WITHIN_X_DAYS",
  "CH_INCLUDE_ZIP_CODES",
  "CH_INCLUDE_UNDECIDED",
  "CH_EXCLUDE_TAGS",
  "OR_STUDY_ONBOARDING_CAPACITY",
];

export const SourceTypeOptions = [`STARR_HBE`];

export const ImportStatuses = {
  FINISHED: "FINISHED",
  FINISHED_WITH_ERROR: "FINISHED_WITH_ERROR",
  FAILED: "FAILED",
};

export const OutreachStatuses = {
  FINISHED: "FINISHED",
  FINISHED_WITH_ERROR: "FINISHED_WITH_ERROR",
  SCHEDULED: "SCHEDULED",
  FAILED: "FAILED",
  GENERATED: "GENERATED",
  GENERATED_WITH_ERROR: "GENERATED_WITH_ERROR",
};

// default data
export const defaultFUDateFrom = new Date("1/1/2020");
export const defaultDateTo = new Date("1/1/2040");
export const defaultRecruitmentDateFrom = new Date("1/1/2010");

export const OutreachChannelModes = {
  STANDARD: "STANDARD",
  DEFAULT: "DEFAULT",
  AUTOMATED: "AUTOMATED",
};

export const OptOutReason = ["UNSUBSCRIBE", "DEATH", "VIP"];
export const OutreachScheduleModes = {
  ONE_TIME_ONLY: "ConductOutreachPage.scheduleOneTimeOnly",
  RECURRING: "ConductOutreachPage.scheduleRecurring",
};

export const DaysOfTheWeek = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const ProspectDefaults = {
  PROSPECTDEFAULTORDERBY: "birthDate",
  PROSPECTDEFAULTORDER: "desc",
  PROSPECTDEFAULTPAGE: 0,
  PROSPECTDEFAULTPAGESIZE: 10,
};

export const DefaultRecurringParam = {
  recurringStatus: "ACTIVE",
};

export const StudyDefaults = {
  STUDYDEFAULTORDERBY: "followUpDate",
  STUDYDEFAULTORDER: "desc",
  STUDYDEFAULTPAGE: 0,
  STUDYDEFAULTPAGESIZE: 25,
  STUDYDEFAULTSTATUS: [
    "NOT_YET_RECRUITING",
    "RECRUITING",
    "ACTIVE_NOT_RECRUITING",
  ],
  STUDYDEFAULTSUSPENDED: false,
};

export const OutreachInitiationModes = {
  SYSTEM: "SYSTEM_INITIATED",
  USER: "USER_INITIATED",
};

export const OutreachHistoryDefaults = {
  OUTREACHHISTORYDEFAULTORDERBY: "conductedDate",
  OUTREACHHISTORYDEFAULTORDER: "desc",
  OUTREACHHISTORYDEFAULTPAGE: 0,
  OUTREACHHISTORYDEFAULTPAGESIZE: 10,
};

export const SummaryAccrual = {
  SummaryAccrualTrue: "Y",
  SummaryAccrualFalse: "N",
};

export const DemographicOptimization = {
  "ENABLED": "Enabled",
  "SYSTEM_DISABLED": "System Disabled",
  "STUDY_DISABLED": "Study Disabled",
  "SYSTEM_AND_STUDY_DISABLED": "System and Study Disabled"
};

export const CancerNonCancer = {
  All: "all",
  Cancer: "cancer",
  NonCancer: "non-cancer",
};

export const PepOnStudy = {
  YES: "Yes",
  NO: "No",
};

export const MultiSite = {
  MultiSiteTrue: "Y",
  MultiSiteFalse: "N",
};

export const CohortHistoryDefaults = {
  COHORT_HISTORY_DEFAULT_ORDERBY: "importedDate",
  COHORT_HISTORY_DEFAULT_ORDER: "desc",
  COHORT_HISTORY_DEFAULT_PAGE: 0,
  COHORT_HISTORY_DEFAULT_PAGESIZE: 10,
};

export const ProspectOutreachHistoryDefaults = {
  OUTREACH_HISTORY_DEFAULT_ORDERBY: "conductedDate",
  OUTREACH_HISTORY_DEFAULT_ORDER: "desc",
  OUTREACH_HISTORY_DEFAULT_PAGE: 0,
  OUTREACH_HISTORY_DEFAULT_PAGESIZE: 10,
};
