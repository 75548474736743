import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  GET_STUDY_CONTACTS_URL,
  GET_STUDY_HBUSERS_URL,
  GET_STUDY_IRBNUMBERS_URL,
  GET_STUDY_NICKNAMES_URL,
  GET_STUDY_DEPTS_URL,
  GET_STUDY_DIVISIONS_URL,
  GET_ALL_HBUSERS_URL,
} from "app/shared/constants";
import { useUsers, useAlerts } from "common";
import { isValidUser, isStudiesLocation } from "app/shared/utils";

/**
 * The purpose of this filterService is to fetch all the data needed for study filter form
 * the axios calls will be called at same time. For performance reason, should be called after
 * getStudies API call since user will not open the StudyFilter form till StudyListing rendered.
 */

const FiltersContext = createContext();

export const FilterProvider = ({ children }) => {
  const { currentUser } = useUsers();
  const { setAlert } = useAlerts();
  const [contacts, setContacts] = useState([]);
  const [honestBrokers, setHonestBrokers] = useState([]);
  const [allHonestBrokers, setAllHonestBrokers] = useState([]);
  const [studyIrbNumbers, setStudyIrbNumbers] = useState([]);
  const [studyNicknames, setStudyNicknames] = useState([]);
  const [studyDepts, setStudyDepts] = useState([]);
  const [studyDivisions, setStudyDivisions] = useState([]);
  const [triggerFiltersRefetch, setTriggerFiltersRefetch] = useState(0);
  const location = useLocation();
  const [initialRender, setInitialRender] = useState(false);

  /**
   * Calling backend APIs to fetch filter data required by Filter form.
   * including: contacts, tags, honest brokers,
   */

  useEffect(() => {
    setInitialRender(false);
    if (isValidUser(currentUser) && isStudiesLocation(location.pathname)) {
      axios
        .all([
          axios.get(GET_STUDY_HBUSERS_URL),
          axios.get(GET_STUDY_CONTACTS_URL),
          axios.get(GET_ALL_HBUSERS_URL),
          axios.get(GET_STUDY_IRBNUMBERS_URL),
          axios.get(GET_STUDY_NICKNAMES_URL),
          axios.get(GET_STUDY_DEPTS_URL),
          axios.get(GET_STUDY_DIVISIONS_URL),
        ])
        .then((response) => {
          // response has an array of 7
          setHonestBrokers(response[0].data);
          setContacts(response[1].data);
          if (response[2].data) {
            setAllHonestBrokers(response[2].data);
            setInitialRender(true);
          }
          setStudyIrbNumbers(response[3].data);
          setStudyNicknames(response[4].data);
          setStudyDepts(response[5].data);
          setStudyDivisions(response[6].data);
        })
        .catch((err) => {
          // set error.message in AlertContext
          setAlert("error", err.message);
        });
    }
  }, [currentUser, location.pathname, setAlert, triggerFiltersRefetch]);
  return (
    <FiltersContext.Provider
      value={{
        contacts,
        honestBrokers,
        studyIrbNumbers,
        studyNicknames,
        studyDepts,
        studyDivisions,
        allHonestBrokers,
        setTriggerFiltersRefetch,
        initialRender,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  return useContext(FiltersContext);
};
