import { useStyles } from "app/study/CreateEditStudyFormStyles";
import {
  CardContent,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Card,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { StudyContactRole } from "app/shared/constants";

const StudyContactsTab = (props) => {
  const {
    disableCreate,
    initData,
    setInitData,
    hookcontrol,
    errors,
    crcError,
    validateLeadCrc,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChangeLeadCRC = (evt, newValue) => {
    // checkbox has name value like this - "<staffId>_leadCRC"
    const contactStaffId = parseInt(evt.target.name);
    // find the contact in the list and update leadCrc flag
    const newContacts = initData.studyContacts.map((contact) => {
      if (contact.staffId === contactStaffId) {
        contact.leadCrc = newValue;
      }
      return contact;
    });

    // and then set initData
    setInitData({ ...initData, ...{ studyContacts: newContacts } });
    validateLeadCrc();
  };

  const displayACard = (contact) => {
    return (
      <Grid
        container
        direction="column"
        key={contact.staffId}
        className={classes.contentNoWrap}
      >
        <Card key={contact.staffId} className={classes.customContactGridWidth}>
          <CardContent>
            <Grid item>
              <TextField
                fullWidth
                name="name"
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                label={t(`formLabel.name`)}
                value={contact.firstName + " " + contact.lastName}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="role"
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                label={t(`formLabel.role`)}
                value={contact.role}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="sunetid"
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                label={t(`formLabel.sunetid`)}
                value={contact.sunetid}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="email"
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                label={t(`formLabel.email`)}
                value={contact.email}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name={`${contact.staffId}_${contact.role}_note`}
                label={t(`formLabel.note`)}
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={hookcontrol.register}
                defaultValue={contact.note}
                disabled={disableCreate}
              />
            </Grid>
            <Grid item>
              {contact.role !== StudyContactRole.PI && (
                <FormControl error={crcError}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contact.leadCrc}
                        color="primary"
                        inputRef={hookcontrol.register}
                        disabled={disableCreate}
                        onChange={handleChangeLeadCRC}
                      />
                    }
                    label={t("formLabel.leadCRC")}
                    name={`${contact.staffId}_${contact.role}_leadCRC`}
                  />
                  {crcError && (
                    <FormHelperText focused={true}>
                      {t("formValidation.oneMainCRC")}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const displayPI = () => {
    // find the PI in studyContacts
    const found = initData.studyContacts.find(
      (contact) => contact.role === StudyContactRole.PI
    );

    return found && displayACard(found);
  };

  return (
    <Grid container spacing={2} className={classes.contentNoWrap}>
      <Grid container direction="column" item xs>
        <Grid item className={classes.customContactGridWidth}>
          <TextField
            fullWidth
            name="recruitmentEmail"
            label={t(`formLabel.recruitmentEmail`)}
            defaultValue={initData.recruitmentEmail}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register({
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            disabled={disableCreate}
            error={errors.recruitmentEmail ? true : false}
            helperText={
              errors.recruitmentEmail && t("formValidation.invalidEmail")
            }
          />
        </Grid>
        <Grid item className={classes.customContactGridWidth}>
          <TextField
            fullWidth
            name="recruitmentPhone"
            label={t(`formLabel.recruitmentPhone`)}
            defaultValue={initData.recruitmentPhone}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register({
              pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            })}
            disabled={disableCreate}
            error={errors.recruitmentPhone ? true : false}
            helperText={
              errors.recruitmentPhone && t("formValidation.invalidPhoneNumber")
            }
          />
        </Grid>
      </Grid>
      <Grid item xs>
        {displayPI()}
      </Grid>
      {initData.studyContacts &&
        initData.studyContacts.map(
          (contact) =>
            contact.role !== StudyContactRole.PI && (
              <Grid item xs>
                {displayACard(contact)}
              </Grid>
            )
        )}
    </Grid>
  );
};

export default StudyContactsTab;
