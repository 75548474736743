import { useStyles } from "app/study/CreateEditStudyFormStyles";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import {
  StudyEntities,
  StudyChannelOptions,
  RecruitmentPreferences,
} from "app/shared/constants";
import PEChipContainer from "app/shared/UI/PEChipContainer";
import PEChip from "app/shared/UI/PEChip";
import { getPrefByName } from "app/shared/utils";

const StudyPreferencesTab = (props) => {
  const {
    disableCreate,
    initData,
    setInitData,
    Controller,
    hookcontrol,
    errors,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  /*
   * since initData.recruitmentPreferences array are not always in same order
   * this function is to find the preference by its name
   */

  const handleChangeCheckbox = (evt, newValue) => {
    const newPrefs = [
      ...initData.recruitmentPreferences.map((pref) => {
        if (pref.name === "CH_INCLUDE_UNDECIDED") {
          pref.value = newValue.toString();
        }
        return pref;
      }),
    ];
    setInitData({ ...initData, ...{ recruitmentPreferences: newPrefs } });
  };

  return (
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="prefChannels" shrink={true}>
            {t("recruitmentPreference." + RecruitmentPreferences[0])}
          </InputLabel>
          <Controller
            as={
              <Select
                multiple
                required
                disabled={disableCreate}
                input={
                  <OutlinedInput
                    id="prefChannels"
                    name="prefChannels"
                    label={t(
                      "recruitmentPreference." + RecruitmentPreferences[0]
                    )}
                    margin="dense"
                    notched
                  />
                }
                renderValue={(selected) => (
                  <PEChipContainer>
                    {selected.map((value) => (
                      <PEChip
                        key={value}
                        label={t("recruitmentPreference." + value)}
                      />
                    ))}
                  </PEChipContainer>
                )}
              >
                {Object.keys(StudyChannelOptions).map((item) => (
                  <MenuItem key={item} value={item}>
                    {t("recruitmentPreference." + item)}
                  </MenuItem>
                ))}
              </Select>
            }
            name="prefChannels"
            control={hookcontrol}
            defaultValue={
              getPrefByName(initData, RecruitmentPreferences[0]) &&
              getPrefByName(initData, RecruitmentPreferences[0]).value
                ? getPrefByName(
                    initData,
                    RecruitmentPreferences[0]
                  ).value.split(",")
                : []
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="prefEntities" shrink={true}>
            {t("recruitmentPreference." + RecruitmentPreferences[1])}
          </InputLabel>
          <Controller
            as={
              <Select
                multiple
                required
                disabled={disableCreate}
                input={
                  <OutlinedInput
                    id="prefEntities"
                    name="prefEntities"
                    label={t(
                      "recruitmentPreference." + RecruitmentPreferences[1]
                    )}
                    margin="dense"
                    size="small"
                    notched
                  />
                }
                renderValue={(selected) => (
                  <PEChipContainer>
                    {selected.map((value) => (
                      <PEChip key={value} label={value} />
                    ))}
                  </PEChipContainer>
                )}
              >
                {StudyEntities.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            }
            name="prefSource"
            control={hookcontrol}
            defaultValue={
              getPrefByName(initData, RecruitmentPreferences[1]) &&
              getPrefByName(initData, RecruitmentPreferences[1]).value
                ? getPrefByName(
                    initData,
                    RecruitmentPreferences[1]
                  ).value.split(",")
                : []
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="prefNotContact"
            name="prefNotContact"
            label={t("recruitmentPreference." + RecruitmentPreferences[2])}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register({ required: true })}
            defaultValue={
              getPrefByName(initData, RecruitmentPreferences[2])
                ? getPrefByName(initData, RecruitmentPreferences[2]).value
                : 0
            }
            className={classes.space}
            error={errors.prefNotContact ? true : false}
            helperText={
              errors.prefNotContact ? t("formValidation.requiredField") : null
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            name="prefProspectActive"
            label={t("recruitmentPreference." + RecruitmentPreferences[3])}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register({ required: true })}
            defaultValue={
              getPrefByName(initData, RecruitmentPreferences[3])
                ? getPrefByName(initData, RecruitmentPreferences[3]).value
                : 0
            }
            className={classes.space}
            error={errors.prefProspectActive ? true : false}
            helperText={
              errors.prefProspectActive
                ? t("formValidation.requiredField")
                : null
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormControlLabel
            fullWidthLabel
            control={
              <Checkbox
                checked={
                  getPrefByName(initData, RecruitmentPreferences[5]) &&
                  getPrefByName(initData, RecruitmentPreferences[5]).value ===
                    "true"
                    ? true
                    : false
                }
                color="primary"
                disabled={disableCreate}
                inputRef={hookcontrol.register}
                onChange={handleChangeCheckbox}
              />
            }
            name="prefIncludeUndecided"
            label={t("recruitmentPreference." + RecruitmentPreferences[5])}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="prefOnboardingCapacity"
            name="prefOnboardingCapacity"
            label={t("recruitmentPreference." + RecruitmentPreferences[7])}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register({ required: true })}
            defaultValue={
              getPrefByName(initData, RecruitmentPreferences[7])
                ? getPrefByName(initData, RecruitmentPreferences[7]).value
                : ""
            }
            className={classes.space}
            error={errors.prefOnboardingCapacity ? true : false}
            helperText={
              errors.prefOnboardingCapacity
                ? t("formValidation.preferenceOnboardingCapacity")
                : null
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          variant="outlined"
          className={classes.areaZipCodes}
        >
          <TextField
            fullWidth
            multiline
            rows={5}
            name="prefIncludeZipCode"
            label={t(`recruitmentPreference.${RecruitmentPreferences[4]}`)}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={hookcontrol.register}
            defaultValue={
              getPrefByName(initData, RecruitmentPreferences[4])
                ? getPrefByName(initData, RecruitmentPreferences[4]).value
                : ""
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default StudyPreferencesTab;
